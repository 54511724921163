<template>
  <div
      :class="company.threePrices.calc.total === maxPrice ? 'max' : company.threePrices.calc.total === minPrice ? 'min' : ''">
    <h2>{{ company.name }} <EcoIcon v-if="company.greenEnergy"/></h2>
    <p class="potencia">Potencia Valle:<span>{{
        company.threePrices.calc.potenciaValle | slideText
      }}</span><span> ({{
        company.threePrices.potenciaValle | slideText
      }}€/kW día)</span>
    </p>
    <p class="potencia">Potencia Punta:<span>{{
        company.threePrices.calc.potenciaPunta | slideText
      }}</span><span> ({{
        company.threePrices.potenciaPunta | slideText
      }}€/kW día)</span>
    </p>

    <p class="total-potencia">Potencia total: <span
        class="hightlight">{{
        company.threePrices.calc.potenciaPunta + company.threePrices.calc.potenciaValle | slideText
      }}</span><span> ({{
        company.threePrices.potenciaValle + company.threePrices.potenciaPunta | slideText
      }}€/kW día)</span></p>
    <p class="potencia">Consumo Punta: <span>{{
        company.threePrices.calc.energiaPunta | slideText
      }}</span><span> ({{
        company.threePrices.energiaPunta | slideText
      }}€/kWh)</span>
    </p>
    <p class="potencia">Consumo Llano: <span>{{
        company.threePrices.calc.energiaLlano | slideText
      }}</span><span> ({{
        company.threePrices.energiaLlano | slideText
      }}€/kWh)</span>
    </p>
    <p class="potencia">Consumo Valle: <span>{{
        company.threePrices.calc.energiaValle | slideText
      }}</span><span> ({{
        company.threePrices.energiaValle | slideText
      }}€/kWh)</span>
    </p>
    <p class="consumo">Consumo Total: <span class="hightlight">{{
        company.threePrices.calc.energiaValle + company.threePrices.calc.energiaPunta + company.threePrices.calc.energiaLlano | slideText
      }}</span>
    </p>
    <p class="consumo">Costes extra fijos: <span class="hightlight">{{ company.threePrices.extra }} €</span>
    </p>
    <p class="total">Total: <span class="total-price">{{ company.threePrices.calc.total | slideText }}</span>
    </p>
  </div>
</template>

<script>
import EcoIcon from "@/components/EcoIcon";

export default {
  name: 'ThreePrices',

  components:{EcoIcon},

  props: {
    company: {
      type: Object,
      required: true,
    },
    maxPrice: {
      type: Number,
      required: true,
    },
    minPrice: {
      type: Number,
      required: true,
    }
  },
  filters: {
    slideText: function (value) {
      value = value.toString()
      return parseFloat(value).toFixed(4)
    }
  },

}
</script>