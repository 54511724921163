<template>
  <div
      :class="company.onePrice.calc.total === maxPrice ? 'max' : company.onePrice.calc.total === minPrice ? 'min' : ''">
    <h2>{{ company.name }} <EcoIcon v-if="company.greenEnergy"/></h2>
    <p class="potencia">Potencia Valle:<span>{{ company.onePrice.calc.potenciaValle | slideText }}</span>
      <span> ( {{ company.onePrice.potenciaValle | slideText }} €/kW día)</span>
    </p>
    <p class="potencia">Potencia Punta:
      <span>{{ company.onePrice.calc.potenciaPunta | slideText }}</span>
      <span> ({{
          company.onePrice.potenciaPunta | slideText
        }}€/kW día)</span>
    </p>

    <p class="total-potencia">Potencia total: <span
        class="hightlight">{{
        company.onePrice.calc.potenciaPunta + company.onePrice.calc.potenciaValle | slideText
      }}</span>
      <span> ({{
          company.onePrice.potenciaValle + company.onePrice.potenciaPunta | slideText
        }}€/kW día)</span>
    </p>
    <p class="consumo">Consumo: <span class="hightlight">{{ company.onePrice.calc.energia | slideText }}</span>
      <span> ({{
          company.onePrice.energia | slideText
        }}€/kWh)</span>
    </p>
    <p class="consumo">Costes extra fijos: <span class="hightlight">{{ company.onePrice.extra }} €</span>
    <p class="total">Total: <span class="total-price">{{ company.onePrice.calc.total | slideText }}</span>
    </p>
  </div>
</template>

<script>
import EcoIcon from "@/components/EcoIcon";

export default {
  name: 'OnePrice',

  components:{EcoIcon},

  props: {
    company: {
      type: Object,
      required: true,
    },
    maxPrice: {
      type: Number,
      required: true,
    },
    minPrice: {
      type: Number,
      required: true,
    }
  },
  filters: {
    slideText: function (value) {
      value = value.toString()
      return parseFloat(value).toFixed(4)
    }
  },

}
</script>