export default {
  lasUpdate: "30/08/2024",
  companies: [
    {
      name: "naturgy",
      greenEnergy: false,
      onePrice: {
        potenciaPunta: 0.103663,
        potenciaValle: 0.034042,
        energia: 0.1149,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: {
        potenciaPunta: (2.515754 * 12) / 365,
        potenciaValle: (0.430677 * 12) / 365,
        energiaPunta: 0.2205,
        energiaLlano: 0.1677,
        energiaValle: 0.1278,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energiaPunta: 0,
          energiaLlano: 0,
          energiaValle: 0,
          total: 0,
        },
      },
    },
    {
      name: "iberdola",
      greenEnergy: true,

      onePrice: {
        potenciaPunta: 39.49 / 365,
        potenciaValle: 15.48 / 365,
        energia: 0.135,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: {
        potenciaPunta: 30.67 / 365,
        potenciaValle: 4.1 / 365,
        energiaPunta: 0.199056,
        energiaLlano: 0.16141,
        energiaValle: 0.149952,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energiaPunta: 0,
          energiaLlano: 0,
          energiaValle: 0,
          total: 0,
        },
      },
    },
    {
      name: "endesa conecta",
      greenEnergy: false,
      onePrice: {
        potenciaPunta: 36.391656 / 365,
        potenciaValle: 11.968848 / 365,
        energia: 0.0957,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: {
        potenciaPunta: 33.861612 / 365,
        potenciaValle: 7.997304 / 365,
        energiaPunta: 0.246561,
        energiaLlano: 0.200215,
        energiaValle: 0.168606,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energiaPunta: 0,
          energiaLlano: 0,
          energiaValle: 0,
          total: 0,
        },
      },
    },
    {
      name: "Gana energia",
      greenEnergy: true,
      onePrice: {
        potenciaPunta: 0.123,
        potenciaValle: 0.05,
        energia: 0.119,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: {
        potenciaPunta: 0.10161,
        potenciaValle: 0.030449,
        energiaPunta: 0.201898,
        energiaLlano: 0.155001,
        energiaValle: 0.135113,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energiaPunta: 0,
          energiaLlano: 0,
          energiaValle: 0,
          total: 0,
        },
      },
    },
    {
      name: "Octopus energy",
      greenEnergy: true,
      onePrice: {
        potenciaPunta: 0.089,
        potenciaValle: 0.026,
        energia: 0.118,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: false,
    },
    {
      name: "repsol",
      greenEnergy: false,
      onePrice: {
        potenciaPunta: 0.068219,
        potenciaValle: 0.068219,
        energia: 0.119746,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: false,
    },
    {
      name: "kewiki",
      greenEnergy: true,
      onePrice: {
        potenciaPunta: 0.1087,
        potenciaValle: 0.0418,
        energia: 0.109,
        extra: 0,
        calc: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          total: 0,
        },
      },
      threePrices: false,
    },
  ],
}
