<template>
  <div id="app" class="container">
    <h1>El Comparador honesto</h1>
    <p style="font-size: 1.2rem;max-width: 800px"><strong>No. Esto no va de decir qué tarifa es la más barata.</strong>
      Que una compañía
      eléctrica sea la más barata depende, además del precio, de nuestro consumo.</p>
    <p style="font-size: 1.2rem;max-width: 800px">Muchas veces nos fijamos únicamente en el precio del kwh para
      contratar una tarifa. La realidad es que <strong>el precio del kwh no es un factor de peso en consumos bajos o
        moderados</strong></p>
    <p style="font-size: 1.2rem;max-width: 800px">Este comparador es honesto y transparente. Introduce tu consumo
      habitual y podrás
      ver los precios de las
      diferentes comercializadoras según su web: <strong>sin
        descuentos, ni impuestos, en bruto</strong>.</p>
    <p style="font-size: 1.2rem;max-width: 800px">Puedes añadir <strong>los precios de la tarifa que te
      ofrecen</strong> para comprobar si es tan buena como parece o en realidad te están, digamos, engañando.</p>
    <p style="font-size: 1.2rem;max-width: 800px;"><span>También <strong>puedes utilizar la tarifa personalizada para compararlo con tu tarifa actual</strong>. Recuerda que estos precios son sin impuestos, así que <strong>debes comparar tu factura antes de impuestos</strong>.</span>
    </p>
    <hr style="margin: 3rem 0">
    <h3>Datos generales de consumo</h3>

    <div class="grid-form">
      <div><p>Consumo total</p>
        <input v-model="tariff.consumo" type="number" min="0" @input="updatePrices">
      </div>
      <div>
        <p>Días</p>
        <input v-model="tariff.dias" type="number" min="0" @input="updatePrices">
      </div>
      <div><p>Potencia Valle</p>
        <input v-model="tariff.potenciaValle" type="number" min="0" @input="updatePrices"></div>
      <div>
        <p>Pontecia Punta</p>
        <input v-model="tariff.potenciaPunta" type="number" min="0" @input="updatePrices"></div>
    </div>
    <button :class="{'active' : threePricesActive}" @click="toggleThreePrices" class="btn-toggle">
      <span></span>
      <span>Tres precios</span>

    </button>
    <template v-if="threePricesActive">
      <h3 style="margin-top: 3rem">Datos generales de consumo para 3 precios</h3>
      <p><strong>¡Ojo!</strong> Los porcentajes no están limitados, así que no te pases del 100%.</p>
      <div class="grid-form grid-form-3">
        <div>
          <p>% consumo en
            <strong>punta</strong>
          </p>
          <input v-model="tariff.consumoPunta" type="number" @input="updatePrices">

        </div>
        <div>
          <p>% consumo en
            <strong>llano</strong>
          </p>
          <input v-model="tariff.consumoLlano" type="number" @input="updatePrices"></div>
        <div>
          <p>% consumo en
            <strong>valle</strong>
          </p>
          <input v-model="tariff.consumoValle" type="number" @input="updatePrices">
        </div>

      </div>
    </template>

    <button :class="{'active' : customPrice}" @click="toggleCustomPrice" class="btn-toggle" style="margin-top: 1rem">
      <span></span>
      <span>Tarifa personalizada</span>
    </button>
    <div class="custom-price-grid" v-if="customPrice">
      <h3>Tarifa personalizada 1 precio</h3>
      <div>
        <p>Precio energía</p>
        <input type="number" v-model="custom.onePrice.energia" min="0" @input="updatePrices">
      </div>
      <div><p>Precio Potencia Punta</p>
        <input type="number" v-model="custom.onePrice.potenciaPunta" min="0" @input="updatePrices"></div>
      <div><p>Precio Potencia Valle</p>
        <input type="number" v-model="custom.onePrice.potenciaValle" min="0" @input="updatePrices"></div>
      <div><p>Costes extra fijos</p>
        <input type="number" v-model="custom.onePrice.extra" min="0" @input="updatePrices"></div>
      <template v-if="customPrice && threePricesActive">
        <h3>Tarifa personalizada 3 precio</h3>
        <div>
          <p>Precio energía Punta</p>
          <input type="number" v-model="custom.threePrices.energiaPunta" min="0" @input="updatePrices">
        </div>
        <div>
          <p>Precio energía Llano</p>
          <input type="number" v-model="custom.threePrices.energiaLlano" min="0" @input="updatePrices">
        </div>
        <div>
          <p>Precio energía Valle</p>
          <input type="number" v-model="custom.threePrices.energiaValle" min="0" @input="updatePrices">
        </div>
        <div><p>Precio Potencia Punta</p>
          <input type="number" v-model="custom.threePrices.potenciaPunta" min="0" @input="updatePrices"></div>
        <div><p>Precio Potencia Valle</p>
          <input type="number" v-model="custom.threePrices.potenciaValle" min="0" @input="updatePrices"></div>
        <div><p>Costes extra fijos</p>
          <input type="number" v-model="custom.threePrices.extra" min="0" @input="updatePrices"></div>
      </template>


    </div>

    <hr style="margin: 3rem 0">

    <h2>Tarifas 1 precio</h2>

    <div class="grid-prices">
      <OnePrice v-if="customPrice" :company="custom" :max-price="maxPrice" :min-price="minPrice"/>
      <OnePrice v-for="company in companies" :key="company.key" :company="company" :max-price="maxPrice"
                :min-price="minPrice"/>
    </div>
    <template v-if="threePricesActive">
      <hr style="margin: 3rem 0">
      <h2>Tarifas 3 precios</h2>
      <p>Las compañías que no aparecen no cuenta con opción de 3 precios.</p>
      <div class="grid-prices">
        <ThreePrices v-if="customPrice" :company="custom" :max-price="maxPrice"
                     :min-price="minPrice"/>
        <ThreePrices v-for="company in companies" :key="company.key" :company="company" :max-price="maxPrice"
                     :min-price="minPrice"/>
      </div>
    </template>

    <p class="update"><span>Precios actualizados al {{ lasUpdate }}</span></p>
    <button class="btn-error" @click="uses.modal = !uses.modal">¿Algún error?</button>
    <div :class="{'active': uses.modal}" class="modal">
      <div class="modal-content">
        <h3>¿Algún error?</h3>
        <p>Puedes mandarme un email para contarme qué sucede</p>
        <button class="btn-error" @click="writeMe()">Escríbeme</button>
      </div>
      <div class="bg-modal" @click="uses.modal = !uses.modal"></div>
    </div>

  </div>
</template>

<script>
import companies from '../public/data'
import OnePrice from "@/components/OnePrice";
import ThreePrices from "@/components/ThreePrices";

export default {
  name: 'App',

  components: {OnePrice, ThreePrices},

  metaInfo: {
    title: 'Comparador eléctrico honesto',
    htmlAttrs: {
      lang: 'es-ES'
    },
    meta: [
      {
        name: 'description',
        content: 'Compara los precios de diferentes compañías eléctricas de forma honesta y transparente. Introduce tu consumo y descubre qué te conviene.'
      },
      {
        name: 'robots', content: 'index, follow'
      }
    ]
  },


  data() {
    return {
      uses: {
        modal: false,
        name: 'comparador',
        lastname: 'honesto',
        web: 'rgdev.ovh',
        extra: '@',
        extraweb: 'mail',
      },
      companies: companies.companies,
      lasUpdate: companies.lasUpdate,
      tariff: {
        consumo: 150,
        consumoPunta: 40,
        consumoValle: 30,
        consumoLlano: 30,
        potenciaPunta: 3.45,
        potenciaValle: 3.45,
        dias: 30,
      },
      custom: {
        name: 'Personalizada',
        onePrice: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energia: 0,
          extra: 0,
          calc: {
            potenciaPunta: 0,
            potenciaValle: 0,
            energia: 0,
            total: 0
          },
        },
        threePrices: {
          potenciaPunta: 0,
          potenciaValle: 0,
          energiaPunta: 0,
          energiaLlano: 0,
          energiaValle: 0,
          extra: 0,
          calc: {
            potenciaPunta: 0,
            potenciaValle: 0,
            energiaPunta: 0,
            energiaLlano: 0,
            energiaValle: 0,
            total: 0,
          }
        }

      },
      maxPrice: 0,
      minPrice: 0,
      threePricesActive: false,
      customPrice: false
    }
  },
  mounted() {
    this.updatePrices()
  },

  methods: {

    onePrice(company) {
      company.calc.energia = parseFloat(company.energia * this.tariff.consumo);
      company.calc.potenciaPunta = parseFloat(company.potenciaPunta * this.tariff.potenciaPunta * this.tariff.dias);
      company.calc.potenciaValle = parseFloat(company.potenciaValle * this.tariff.potenciaValle * this.tariff.dias);
      company.calc.total = parseFloat(company.calc.energia + company.calc.potenciaPunta + company.calc.potenciaValle +
          company.extra);
    },
    threePrices(company) {
      if (company) {
        company.calc.potenciaPunta = parseFloat(company.potenciaPunta * this.tariff.potenciaPunta * this.tariff.dias);
        company.calc.potenciaValle = parseFloat(company.potenciaValle * this.tariff.potenciaValle * this.tariff.dias);
        company.calc.energiaPunta = parseFloat(company.energiaPunta * this.tariff.consumo * (this.tariff.consumoPunta / 100));
        company.calc.energiaLlano = parseFloat(company.energiaLlano * this.tariff.consumo * (this.tariff.consumoLlano / 100));
        company.calc.energiaValle = parseFloat(company.energiaValle * this.tariff.consumo * (this.tariff.consumoValle / 100));
        company.calc.total = parseFloat(company.calc.energiaPunta + company.calc.energiaLlano + company.calc.energiaValle + company.calc.potenciaPunta + company.calc.potenciaValle +
            company.extra);
      }

    },
    writeMe() {
      window.location.href = `${this.uses.extraweb}to:${this.uses.name}${this.uses.lastname}${this.uses.extra}${this.uses.web}`
    },
    toggleThreePrices() {
      this.threePricesActive = !this.threePricesActive;
      this.updatePrices()
    },
    toggleCustomPrice() {
      this.customPrice = !this.customPrice;
      this.updatePrices()
    },
    updatePrices() {
      this.companies.forEach(company => {
        this.onePrice(company.onePrice)
        if (this.threePricesActive) {
          this.threePrices(company.threePrices)
        }
      })
      if (this.customPrice) {
        this.onePrice(this.custom.onePrice)
        if (this.threePricesActive) {
          this.threePrices(this.custom.threePrices)
        }
      }

      const resultOnePrice = this.companies.map(el => el.onePrice.calc.total)
      let resultThreePriceFilter = []
      if (this.threePricesActive) {
        let resultThreePrice = this.companies.map(el => {
          if (el.threePrices) {
            return el.threePrices.calc.total
          }
        })
        resultThreePriceFilter = resultThreePrice.filter(Boolean)
      }
      const result = resultOnePrice.concat(resultThreePriceFilter)
      if (this.custom.onePrice.calc.total !== 0 && this.customPrice) {
        result.push(this.custom.onePrice.calc.total)
      }
      if (this.custom.threePrices.calc.total !== 0 && this.customPrice && this.threePricesActive) {
        result.push(this.custom.threePrices.calc.total)
      }
      this.maxPrice = Math.max(...result.values())
      this.minPrice = Math.min(...result.values())
    }
  }
}
</script>
